@import "../../variables.scss";
@import "./Ani.module.scss";

.cards {
	display: flex;
	justify-content: space-between;
	gap: 20px;

	@media screen and (max-width: 1280px) {
		height: 350px;
	}
	@media screen and (max-width: 560px) {
		height: auto;
		justify-content: start;
		flex-direction: column-reverse;
	}
	.card__img {
		position: relative;
		margin-left: -130px;
		max-width: 670px;
		width: 100%;
		height: 380px;
		background: none;
		@media screen and (max-width: 1280px) {
			height: 400px;
		}
		@media screen and (max-width: 992px) {
			overflow: visible;
			height: 350px;
			max-width: 540px;
			margin-left: -220px;
		}
		@media screen and (max-width: 700px) {
			height: 300px;
		}
		@media screen and (max-width: 575px) {
			max-width: 260px;
			margin-left: -245px;
		}
		@media screen and (max-width: 560px) {
			margin-left: -20px;
			max-width: 100%;
			height: 320px;
		}
		@media screen and (max-width: 420px) {
			height: 260px;
		}
		@media screen and (max-width: 320px) {
			height: 185px;
		}

		.card__imgOne {
			position: absolute;
			z-index: 5;
			-webkit-transform: translateX(290px);
			-ms-transform: translateX(290px);
			transform: translate(300px, 130px);
			-webkit-animation: -webkit-aniCardOne 3s linear;
			animation: aniCardOne 3s linear;

			@media screen and (max-width: 1280px) {
				animation: aniCardOne1280 1.5s linear;
				transform: translate(267px, 115px);
			}
			@media screen and (max-width: 992px) {
				animation: aniCardOne992 2s linear;
				transform: translate(280px, 115px);
			}
			@media screen and (max-width: 790px) {
				z-index: 2;
				animation: aniCardOne790 1.5s linear;
				transform: translate(147px, 100px);
			}

			@media screen and (max-width: 560px) {
				animation: aniCardOne560 1.5s linear;
				transform: translate(223px, 127px);
				-webkit-filter: drop-shadow(0px 17.7415px 17.7415px rgba(0, 0, 0, 0.1));
				filter: drop-shadow(0px 17.7415px 17.7415px rgba(0, 0, 0, 0.1));
			}
			@media screen and (max-width: 420px) {
				animation: aniCardOne420 1.5s linear;
				transform: translate(174px, 102px);
			}
			@media screen and (max-width: 320px) {
				animation: aniCardOne320 1.5s linear;
				transform: translate(126px, 75px);
			}
			img {
				width: 360px;
				@media screen and (max-width: 1280px) {
					width: 300px;
				}
				@media screen and (max-width: 768px) {
					width: 280px;
				}
				@media screen and (max-width: 605px) {
					width: 220px;
				}
				@media screen and (max-width: 560px) {
					width: 300px;
				}
				@media screen and (max-width: 420px) {
					width: 240px;
				}
				@media screen and (max-width: 320px) {
					width: 170px;
				}
			}
		}
		.card__imgTwo {
			-webkit-animation: aniCardTwo 1.5s linear;
			animation: aniCardTwo 1.5s linear;
			position: absolute;
			transform: translate(72px, 0);

			@media screen and (max-width: 1280px) {
				animation: aniCardTwo1280 1.5s linear;
				transform: translateX(67px);
			}
			@media screen and (max-width: 992px) {
				animation: aniCardTwo992 1.5s linear;
				transform: translateX(80px);
			}
			@media screen and (max-width: 790px) {
				z-index: 1;
				animation: aniCardTwo768 1.5s linear;
				transform: translate(150px, 200px);
			}

			@media screen and (max-width: 560px) {
				animation: aniCardTwo560 1.5s linear;
				transform: translate(15px, 10px);
				-webkit-filter: drop-shadow(0px 30.8803px 30.8803px rgba(0, 0, 0, 0.1));
				filter: drop-shadow(0px 30.8803px 30.8803px rgba(0, 0, 0, 0.1));
			}
			@media screen and (max-width: 320px) {
				animation: aniCardTwo320 1.5s linear;
				transform: translate(15px, 10px);
			}

			img {
				width: 350px;
				@media screen and (max-width: 1280px) {
					width: 300px;
				}
				@media screen and (max-width: 768px) {
					width: 270px;
				}
				@media screen and (max-width: 605px) {
					width: 220px;
				}
				@media screen and (max-width: 560px) {
					width: 305px;
				}
				@media screen and (max-width: 420px) {
					width: 240px;
				}
				@media screen and (max-width: 320px) {
					width: 170px;
				}
			}
		}
		.card__imgThree {
			-webkit-animation: aniCardThree 1.5s linear;
			animation: aniCardThree 1.5s linear;
			position: absolute;
			z-index: 1;
			transform: translate(-1px, 145px);
			@media screen and (max-width: 1280px) {
				animation: aniCardThree1280 1.3s linear;
				transform: translate(65px, 165px);
			}
			@media screen and (max-width: 992px) {
				animation: aniCardThree992 1.5s linear;
				transform: translate(14px, 120px);
			}

			@media screen and (max-width: 790px) {
				z-index: 3;
				animation: aniCardThree790 1.5s linear;
				transform: translate(144px, 0px);
			}

			@media screen and (max-width: 560px) {
				animation: aniCardThree560 1.5s linear;
				transform: translate(-13px, 150px);
				-webkit-filter: drop-shadow(0px 30.8803px 30.8803px rgba(0, 0, 0, 0.1));
				filter: drop-shadow(0px 30.8803px 30.8803px rgba(0, 0, 0, 0.1));
			}
			@media screen and (max-width: 420px) {
				animation: aniCardThree420 1.5s linear;
				transform: translate(-13px, 117px);
			}
			@media screen and (max-width: 320px) {
				animation: aniCardThree320 1.5s linear;
				transform: translate(-13px, 85px);
			}
			img {
				width: 380px;
				@media screen and (max-width: 1280px) {
					width: 320px;
				}
				@media screen and (max-width: 768px) {
					width: 285px;
				}
				@media screen and (max-width: 605px) {
					width: 225px;
				}
				@media screen and (max-width: 560px) {
					width: 290px;
				}
				@media screen and (max-width: 420px) {
					width: 240px;
				}
				@media screen and (max-width: 320px) {
					width: 175px;
				}
			}
		}
	}

	.card__info {
		width: 490px;
		height: 294px;
		@media screen and (max-width: 992px) {
			max-width: 400px;
			width: 100%;
		}
		@media screen and (max-width: 890px) {
			max-width: 280px;
		}
		@media screen and (max-width: 575px) {
			height: auto;
			max-width: 280px;
		}
		@media screen and (max-width: 560px) {
			max-width: 100%;
		}
		.card__icon {
			margin-bottom: 40px;
			padding: 20.55px 12.83px;
			width: 54px;
			height: 54px;
			background: $primary-main;
			box-shadow: 0px 3px 24px rgba(116, 139, 255, 0.51), inset 3px 4px 7px rgba(255, 255, 255, 0.31);
			border-radius: 40px;
			@media screen and (max-width: 768px) {
				margin-bottom: 30px;
				padding: 10px;
				width: 44px;
				height: 44px;
				padding: 14px 7px;
			}
			@media screen and (max-width: 560px) {
				margin-bottom: 20px;
				width: 40px;
				height: 40px;
				padding: 13px 7px;
			}
			img {
				@media screen and (max-width: 560px) {
					width: 25px;
					height: 15px;
				}
			}
		}
		.card__block {
			p:first-child {
				font-weight: 500;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.003em;
				color: $primary-main;
				margin-bottom: 10px;
			}
			p:last-child {
				font-weight: 400;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $sub-title;
				@media screen and (max-width: 768px) {
					font-size: 14px;
					line-height: 120%;
				}
			}
			h2 {
				font-weight: 700;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $color-text;
				margin-bottom: 20px;
				@media screen and (max-width: 992px) {
					font-size: 23px;
					line-height: 120%;
				}
				@media screen and (max-width: 768px) {
					font-size: 18px;
					line-height: 120%;
				}
			}
		}
	}
}
