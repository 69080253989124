@import "../../components/variables.scss";

.inpEmpty {
	width: 358px;
	@media screen and (max-width: 450px) {
		width: 100%;
	}
	label {
		transition: all 0.2s linear;
		font-family: "Gotham Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
		letter-spacing: 0.003em;
		color: $text-non-active;
		background: $white;
		font-size: 14px;
		transform: translate(14px, 16px) scale(1);
		z-index: 3;
	}
	div {
		height: 50px;
		border: none;
		outline: none;

		&:focus input > label {
			top: -15px;
		}

		input {
			width: 100%;
			height: 16px;
			color: $color-text;
			transition: all 0.2s linear;
			padding: 16px;
			background: $white;
			border: 1px solid #d5dcff;
			border-radius: 22px;
			position: relative;
			padding: 16px;
			&:hover {
				border: 1px solid #748bff;
			}
		}
		input[type="number"]::-webkit-inner-spin-button,
		input[type="number"]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		fieldset {
			border: none;
			outline: none;
		}
	}
	&:focus-within label {
		color: $primary-main;
		transform: translate(14px, -8px) scale(1);
	}
}

.inp {
	width: 358px;
	@media screen and (max-width: 450px) {
		width: 100%;
	}
	label {
		transition: all 0.2s linear;
		font-family: "Gotham Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
		letter-spacing: 0.003em;
		color: $text-non-active;
		background: $white;
		font-size: 14px;
		transform: translate(14px, -8px) scale(1);
	}
	div {
		border: none;
		outline: none;

		input {
			width: 100%;
			height: 16px;
			transition: all 0.2s linear;
			padding: 16px;
			background: $white;
			border: 1px solid #d5dcff;
			border-radius: 22px;
			position: relative;
			padding: 16px;
			&:hover {
				border: 1px solid #748bff;
			}
		}
		input[type="number"]::-webkit-inner-spin-button,
		input[type="number"]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		fieldset {
			border: none;
			outline: none;
		}
	}
	&:focus-within label {
		color: $primary-main;
		transform: translate(14px, -8px) scale(1);
	}
}

.inp:hover label {
}
