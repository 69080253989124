.svg {
	width: 152px;
	height: 152px;
}
@media screen and (max-width: 768px) {
	.svg {
		width: 120px;
		height: 120px;
	}
	.eboqmdWA_0,
	.eboqmdWA_1,
	.eboqmdWA_2,
	.eboqmdWA_3,
	.eboqmdWA_4,
	.eboqmdWA_5,
	.eboqmdWA_6 {
		animation: none;
	}
}
.eboqmdWA_0 {
	stroke-dasharray: 195 197;
	stroke-dashoffset: 196;
	animation: eboqmdWA_draw 0.8s linear 0ms forwards;
}
.eboqmdWA_1 {
	stroke-dasharray: 195 197;
	stroke-dashoffset: 196;
	animation: eboqmdWA_draw 0.8s linear 166ms forwards;
}
.eboqmdWA_2 {
	stroke-dasharray: 565 567;
	stroke-dashoffset: 566;
	animation: eboqmdWA_draw 0.8s linear 333ms forwards;
}
.eboqmdWA_3 {
	stroke-dasharray: 566 568;
	stroke-dashoffset: 567;
	animation: eboqmdWA_draw 0.8s linear 500ms forwards;
}
.eboqmdWA_4 {
	stroke-dasharray: 3632 3634;
	stroke-dashoffset: 3633;
	animation: eboqmdWA_draw 0.8s linear 666ms forwards;
}
.eboqmdWA_5 {
	stroke-dasharray: 165 167;
	stroke-dashoffset: 166;
	animation: eboqmdWA_draw 0.8s linear 833ms forwards;
}
.eboqmdWA_6 {
	stroke-dasharray: 165 167;
	stroke-dashoffset: 166;
	animation: eboqmdWA_draw 0.8s linear 1000ms forwards;
}
@keyframes eboqmdWA_draw {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes eboqmdWA_fade {
	0% {
		stroke-opacity: 1;
	}
	94.44444444444444% {
		stroke-opacity: 1;
	}
	100% {
		stroke-opacity: 0;
	}
}
