@import "../variables.scss";

.container {
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 50;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 50px 0;
	.cookie {
		padding: 30px 20px;
		background: #ffffff;
		max-width: 1440px;
		height: auto;
		margin: 0 auto;
		width: calc(100% - 200px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		padding: 30px 20px;
		background: #ffffff;
		border: 1px solid #e3e8ff;
		box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
		border-radius: 20px;
		@media screen and (max-width: 1285px) {
			width: calc(100% - 100px);
		}
		@media screen and (max-width: 1050px) {
			max-width: 932px;
			width: calc(100% - 100px);
			padding: 30px 20px;
		}

		@media screen and (max-width: 768px) {
			width: calc(100% - 60px);
		}
		@media screen and (max-width: 575px) {
			flex-direction: column;
			gap: 48px;
		}

		@media screen and (max-width: 560px) {
			padding: 15px;
			gap: 20px;
		}
		@media screen and (max-width: 450px) {
			width: calc(100% - 36px);
		}

		&__left {
			max-width: 700px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 15px;

			@media screen and (max-width: 992px) {
				max-width: 550px;
			}
			@media screen and (max-width: 768px) {
				gap: 40px;
				max-width: 440px;
			}
			@media screen and (max-width: 560px) {
				max-width: 100%;
				gap: 20px;
			}
			img {
				@media screen and (max-width: 560px) {
					width: 50px;
				}
				@media screen and (max-width: 380px) {
					display: none;
				}
			}
			p {
				max-width: 596px;
				font-weight: 400;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: #636363;

				@media screen and (max-width: 768px) {
					max-width: 500px;
				}
				@media screen and (max-width: 560px) {
					max-width: 100%;
				}
			}
			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 120%;
				letter-spacing: 0.02em;
				color: #636363;
				display: none;
				@media screen and (max-width: 560px) {
					display: block;
				}
			}
		}

		.accept {
			font-weight: 500;
			position: relative;
			transition: al 0.5s linear;
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 10px 20px;
			gap: 6px;
			color: #ffffff;
			background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
			border-radius: 22px;
			margin-right: 10px;

			z-index: 49;
			&::before {
				content: "";
				display: block;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
				transition: opacity 0.5s ease-in-out;
				border-radius: 22px;
				z-index: -49;
			}

			&:hover::before {
				background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
				opacity: 1;
			}
			@media screen and (max-width: 768px) {
				justify-content: center;
				flex: 1;
			}
			@media screen and (max-width: 575px) {
				width: 250px;
			}
		}
	}
}
.container__none {
	display: none;
}
