@import "../../variables.scss";

.notification {
	position: relative;
	display: flex;
	justify-content: space-between;
	gap: 40px;
	width: 100%;
	height: 355px;
	@media screen and (max-width: 768px) {
		gap: 0;
	}
	@media screen and (max-width: 560px) {
		height: auto;
		flex-direction: column;
		gap: 17px;
	}
	.notification__left {
		width: 490px;
		@media screen and (max-width: 992px) {
			width: 400px;
		}
		@media screen and (max-width: 768px) {
			width: 280px;
		}
		@media screen and (max-width: 560px) {
			width: 100%;
		}

		.notification__icon {
			padding: 10px;
			width: 54px;
			height: 54px;
			background: $primary-main;
			box-shadow: 0px 3px 24px rgba(116, 139, 255, 0.51), inset 3px 4px 7px rgba(255, 255, 255, 0.31);
			border-radius: 40px;
			margin-bottom: 40px;
			@media screen and (max-width: 768px) {
				margin-bottom: 30px;
				padding: 10px;
				width: 44px;
				height: 44px;
			}
			@media screen and (max-width: 560px) {
				margin-bottom: 20px;
				width: 40px;
				height: 40px;
			}
			img {
				@media screen and (max-width: 768px) {
					width: 24px;
					height: 24px;
				}
				@media screen and (max-width: 560px) {
					width: 20px;
					height: 20px;
				}
			}
		}
		.notification__block {
			p:first-child {
				font-weight: 500;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.003em;
				color: $primary-main;
				margin-bottom: 10px;
			}
			p:last-child {
				font-weight: 400;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $sub-title;
				@media screen and (max-width: 768px) {
					font-size: 14px;
				}
			}
			h2 {
				font-weight: 700;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $color-text;
				margin-bottom: 20px;
				@media screen and (max-width: 992px) {
					font-size: 23px;
					line-height: 120%;
				}
				@media screen and (max-width: 768px) {
					font-size: 18px;
				}
			}
		}
	}
	.notification__right {
		width: 596px;
		margin-top: -20px;
		margin-right: -50px;
		z-index: 4;
		@media screen and (max-width: 992px) {
			width: 340px;
		}
		@media screen and (max-width: 768px) {
			width: 320px;
		}
		@media screen and (max-width: 575px) {
			width: 220px;
		}
		@media screen and (max-width: 560px) {
			height: 250px;
		}
		img {
			width: 700px;
			@media screen and (max-width: 992px) {
				width: 600px;
			}
			@media screen and (max-width: 768px) {
				width: 600px;
			}
			@media screen and (max-width: 575px) {
				width: 460px;
			}
			@media screen and (max-width: 560px) {
				width: 550px;
			}
		}
		.ani {
			position: relative;
			animation: ani 0.5s linear;
			.notiOne {
				position: absolute;
				z-index: 6;
				margin-left: -45px;
				top: 80px;

				@media screen and (max-width: 992px) {
					margin-left: -26px;
				}
				@media screen and (max-width: 768px) {
					top: 75px;
				}
				@media screen and (max-width: 575px) {
					top: 112px;
				}
				@media screen and (max-width: 560px) {
					margin-left: -36px;
					top: 72px;
				}
				img {
					width: 840px !important;
					@media screen and (max-width: 992px) {
						width: 700px !important;
					}
					@media screen and (max-width: 575px) {
						width: 550px !important;
					}
					@media screen and (max-width: 560px) {
						width: 660px !important;
					}
				}
			}
			.notiTwo {
				position: absolute;
				z-index: 5;
				opacity: 0.8;
				left: 25px;
				box-shadow: 14.4972px 24.6452px 42.7667px 5.07401px rgba(0, 25, 225, 0.05);
				border-radius: 20px;
				@media screen and (max-width: 575px) {
					left: 20px;
					top: 55px;
				}
				@media screen and (max-width: 560px) {
					top: 0;
				}
			}
		}
	}
	.blockBlur__notification {
		z-index: 1;
		width: 520px;
		height: 520px;
		position: absolute;
		left: 837px;
		top: 108px;
		background: -o-linear-gradient(336.52deg, #ff8de6 7.05%, #4c3fe7 105.43%);
		background: linear-gradient(113.48deg, #ff8de6 7.05%, #4c3fe7 105.43%);
		opacity: 0.25;
		-webkit-filter: blur(112px);
		filter: blur(112px);
		@media screen and (max-width: 768px) {
			left: 200px;
		}
		@media screen and (max-width: 560px) {
			display: none;
		}
	}
}

@keyframes ani {
	from {
		transform: translate(100%, 0);
	}
	to {
		transform: translate(0, 0);
	}
}
