@import "../../variables.scss";
@import "../OpportunitiesAni.scss";

.score {
	position: relative;
	z-index: 5;
	width: 100%;
	height: 316px;
	display: flex;
	justify-content: space-between;
	margin-top: 60px;
	@media screen and (max-width: 1100px) {
		height: 100%;
	}
	@media screen and (max-width: 575px) {
		height: 266px;
		margin-top: 40px;
	}
	@media screen and (max-width: 560px) {
		justify-content: start;
		flex-direction: column;
		gap: 10px;
		height: auto;
	}

	&__left {
		max-width: 490px;
		width: 100%;
		@media screen and (max-width: 1100px) {
			max-width: 400px;
			height: 100%;
		}
		@media screen and (max-width: 970px) {
			max-width: 280px;
		}

		@media screen and (max-width: 560px) {
			height: 100%;
			max-width: 100%;
		}
		.score__icon {
			padding: 10px;
			margin-bottom: 40px;
			width: 54px;
			height: 54px;
			background: $primary-main;
			box-shadow: 0px 3px 24px rgba(116, 139, 255, 0.51), inset 3px 4px 7px rgba(255, 255, 255, 0.31);
			border-radius: 40px;
			@media screen and (max-width: 768px) {
				margin-bottom: 30px;
				width: 44px;
				height: 44px;
			}
			@media screen and (max-width: 560px) {
				margin-bottom: 20px;
				width: 40px;
				height: 40px;
			}
			img {
				@media screen and (max-width: 768px) {
					width: 24px;
					height: 24px;
				}
				@media screen and (max-width: 560px) {
					width: 20px;
					height: 20px;
				}
			}
		}
		.score__block {
			p {
				width: 100%;
			}
			p:first-child {
				font-weight: 500;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.003em;
				color: $primary-main;
				margin-bottom: 10px;
			}
			p:last-child {
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $sub-title;
				@media screen and (max-width: 970px) {
					font-size: 14px;
					line-height: 130%;
				}
			}
			h2 {
				font-weight: 700;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $color-text;
				margin-bottom: 20px;
				@media screen and (max-width: 970px) {
					font-size: 23px;
					line-height: 130%;
				}
				@media screen and (max-width: 970px) {
					font-size: 18px;
					line-height: 130%;
				}
			}
		}
	}

	&__right {
		padding-top: 20px;
		position: relative;
		width: 440px;
		height: 100%;
		@media screen and (max-width: 992px) {
			position: absolute;
			right: -189px;
		}
		@media screen and (max-width: 768px) {
			right: -190px;
		}
		@media screen and (max-width: 575px) {
			right: -260px;
		}

		@media screen and (max-width: 560px) {
			position: static;
			width: 400px;
			margin: 0 auto;
			margin-left: -15px;
		}

		.right__iconWire {
			position: absolute;
			top: 0;
			right: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 75.35px;
			height: 36.18px;
			background: $primary-main;
			border: 2.36267px solid rgba(255, 255, 255, 0.4);
			box-shadow: 0px 16.5387px 25.9893px rgba(21, 21, 21, 0.08);
			border-radius: 19px;
			p {
				font-weight: 400;
				font-size: 16.5387px;
				line-height: 130%;
				color: $white;
			}
		}
		.right__icon {
			width: 38px;
			height: 38px;
			position: absolute;
			bottom: 95px;
			right: 6px;
		}
		.right__iconUSDT {
			position: absolute;
			bottom: 55px;
			left: 55px;
			padding: 5.08px 0 0 12.17px;
			width: 109.86px;
			height: 35.44px;
			background: $primary-light;
			border: 2.36267px solid rgba(255, 255, 255, 0.4);
			box-shadow: 0px 16.5387px 25.9893px rgba(21, 21, 21, 0.08);
			border-radius: 18.9013px;
			p {
				color: $white;
				font-weight: 400;
				font-size: 13.5px;
				line-height: 130%;
			}
		}
	}
}
