@import "../variables.scss";

.dropdown {
	width: 65px;
	height: 30px;
	border: none;
	&:hover .dropdown__select {
		background: #ffffff;
		border: 1px solid $primary-main;
		color: #748bff;
		border-radius: 22px;
	}

	.dropdown__select {
		width: 100%;
		height: 100%;
		cursor: pointer;
		border: 1px solid $primary-light;
		transition: all 0.5s ease-in-out;
		gap: 4px;
		background: $white;
		border-radius: 22px;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-style: normal;
			height: 15px;
			font-weight: 500;
			font-size: 13px;
			line-height: 120%;
			color: $primary-main;
		}

		.active {
			-webkit-transition: all 0.2s;
			-o-transition: all 0.2s;
			transition: all 0.2s;
			-webkit-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
	}

	.dropdown__list {
		width: 65px;
		margin-top: 6px;
		padding: 4px;
		border: 1px solid #e3e8ff;
		box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
		border-radius: 6px;
		background: $white;

		flex-direction: column;
		align-items: center;
		display: flex;
		transition: opacity 0.2s linear, visibility 0.2s linear;

		.dropdown__list__item {
			cursor: pointer;
			width: 100%;
			padding: 8px 0 6px 8px;
			border-radius: 6px;
			margin: 1px;
			border: 1px solid $white;
			transition: all 0.3s cubic-bezier(0.08, 0.43, 0.18, 0.72);

			&:hover {
				background: #ffffff;
				border: 1px solid #748bff;
				border-radius: 6px;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 120%;
			}
		}
		.dropdown__list__itemActive {
			width: 100%;
			padding: 8px 0 6px 8px;
			color: $primary-main;
			background: $hover;
			border-radius: 6px;

			p {
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 120%;
			}
			span {
				height: 15px;
			}
		}
	}
}

.dropdown__none {
	width: 65px;
	height: 30px;
	border: none;
	&:hover .dropdown__select {
		background: none;
		border: 1px solid $primary-main;
		color: #748bff;
		border-radius: 22px;
	}
	.dropdown__select {
		width: 100%;
		height: 100%;
		cursor: pointer;
		border: 1px solid $primary-light;
		-webkit-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		gap: 4px;
		background: $white;
		border-radius: 22px;

		display: -webkit-box;
		display: -ms-flexbox;

		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;

		span {
			font-style: normal;
			height: 15px;
			font-weight: 500;
			font-size: 13px;
			line-height: 120%;
			color: $primary-main;
		}
		img {
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
	}

	.dropdown__list {
		opacity: 0;
		visibility: hidden;
		.dropdown__list__item {
			cursor: pointer;
			width: 100%;
			padding: 8px 0 6px 8px;
			border-radius: 6px;
			margin: 1px;
			border: 1px solid $white;
			transition: all 0.3s cubic-bezier(0.08, 0.43, 0.18, 0.72);

			&:hover {
				background: #ffffff;
				border: 1px solid #748bff;
				border-radius: 6px;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 120%;
			}
		}
	}
}
