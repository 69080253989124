@import "../variables.scss";

.container {
	width: 100%;
	position: relative;
	margin-top: 50px;
	margin-bottom: 120px;
	@media screen and (max-width: 1280px) {
		margin: 0;
	}
	@media screen and (max-width: 992px) {
		height: 370px;
	}
	@media screen and (max-width: 920px) {
		height: auto;
	}
	.about__bg {
		display: flex;
		width: 100%;
		height: 400px;
		position: absolute;
		margin-left: 700px;
		z-index: 5;
		@media screen and (max-width: 1440px) {
			margin-left: 650px;
		}
		@media screen and (max-width: 1280px) {
			margin-left: 570px;
			margin-top: 30px;
		}
		@media screen and (max-width: 992px) {
			height: 420px;
			margin-left: 530px;
			margin-top: -56px;
		}
		@media screen and (max-width: 768px) {
			height: 420px;
			margin-left: -290px;
			margin-top: 120px;
		}
		@media screen and (max-width: 575px) {
			margin-left: -315px;
			margin-top: 115px;
		}
		@media screen and (max-width: 560px) {
			margin-left: -270px;
		}
		img {
			padding: 0;
		}
	}

	.about {
		position: relative;
		z-index: 10;
		max-width: 1440px;
		width: calc(100% - 200px);
		margin: 50px auto 0 auto;
		@media screen and (max-width: 1285px) {
			width: calc(100% - 100px);
			margin-bottom: 50px;
			margin-top: 0;
		}
		@media screen and (max-width: 1050px) {
			max-width: 932px;
			width: calc(100% - 100px);
		}
		@media screen and (max-width: 992px) {
			margin: 0 auto;
		}
		@media screen and (max-width: 575px) {
			width: calc(100% - 60px);
			margin: 0 auto;
		}
		@media screen and (max-width: 450px) {
			width: calc(100% - 36px);
		}
		.about__title {
			max-width: 639px;
			width: 100%;
			margin-bottom: 40px;
			.megaTitle {
				font-weight: 700;
				font-size: 42px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $color-text;
				margin-bottom: 20px;
				@media screen and (max-width: 768px) {
					font-size: 32px;
					line-height: 120%;
				}
				@media screen and (max-width: 560px) {
					font-size: 18px;
					line-height: 120%;
				}
			}
			.subTitle {
				font-weight: 400;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $sub-title;
				@media screen and (max-width: 560px) {
					font-size: 14 px;
					line-height: 130%;
				}
			}
		}
		.about__numbers {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			gap: 40px;
			@media screen and (max-width: 595px) {
				gap: 16px;
			}
			div {
				-webkit-box-flex: 1;
				-ms-flex: 1;
				flex: 1;
				min-width: 247px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				gap: 10px;
				border: 1px solid #642dff;
				border-radius: 20px;
				width: 386px;
				height: 162px;
				padding: 20px;
				gap: 10px;
				background: rgba(255, 255, 255, 0.6);
				-webkit-backdrop-filter: blur(2px);
				backdrop-filter: blur(2px);
				@media screen and (max-width: 1050px) {
					max-width: 100%;
				}
				img {
					width: 38px;
				}
				.about__title {
					width: 100%;
					font-weight: 700;
					font-size: 23px;
					line-height: 120%;
					letter-spacing: 0.003em;
					color: $color-text;
					margin-bottom: 0;
				}
				.about__subTitle {
					width: 170px;
					font-weight: 400;
					font-size: 14px;
					line-height: 130%;
					letter-spacing: 0.003em;
					color: $sub-title;
				}
				.about__subTitleThree {
					width: 205px;
					font-weight: 400;
					font-size: 14px;
					line-height: 130%;
					letter-spacing: 0.003em;
					color: $sub-title;
				}
			}
		}
	}
	.link {
		position: absolute;
		top: -80px;
		@media screen and (max-width: 992px) {
			top: -120px;
		}
	}
}
