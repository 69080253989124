@import url("./assets/fonts/stylesheet.css");
* {
	font-family: "Gotham Pro";
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.App {
	width: 100%;
	overflow: hidden;
	height: 100%;
	scroll-padding-top: 1100px;
}

html,
body {
	padding: 0;
	margin: 0;
	color: var(--black-text);
	font-size: 16px;
	background: #fff;
}

a {
	color: inherit;
	text-decoration: none;
}

ul,
li {
	list-style: none;
}
