@import "../../variables.scss";
@import "../OpportunitiesAni.scss";

.distribution {
	width: 100%;
	height: 355px;
	position: relative;
	z-index: 7;
	display: flex;
	justify-content: space-between;
	gap: 40px;
	@media screen and (max-width: 1430px) {
		height: 378px;
	}
	@media screen and (max-width: 1280px) {
		height: 290px;
	}
	@media screen and (max-width: 992px) {
		padding: 20px 0;
		align-items: center;
		height: auto;
	}
	@media screen and (max-width: 575px) {
		padding: 0;
		gap: 0;
	}
	@media screen and (max-width: 560px) {
		gap: 20px;
		align-items: start;
		flex-direction: column;
	}
	&__left {
		width: 490px;
		height: 100%;
		@media screen and (max-width: 992px) {
			width: 400px;
		}
		@media screen and (max-width: 768px) {
			flex: 1;
			max-width: 280px;
			width: 100%;
		}

		@media screen and (max-width: 560px) {
			max-width: 100%;
		}
		.distribution__icon {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 40px;
			width: 54px;
			height: 54px;
			background: $primary-main;
			box-shadow: 0px 3px 24px rgba(116, 139, 255, 0.51), inset 3px 4px 7px rgba(255, 255, 255, 0.31);
			border-radius: 40px;
			@media screen and (max-width: 768px) {
				margin-bottom: 30px;
				padding: 10px;
				width: 44px;
				height: 44px;
			}
			@media screen and (max-width: 560px) {
				margin-bottom: 20px;
				width: 40px;
				height: 40px;
			}

			img {
				@media screen and (max-width: 768px) {
					width: 24px;
					height: 24px;
				}
				@media screen and (max-width: 560px) {
					width: 20px;
					height: 20px;
				}
			}
		}
		.distribution__info {
			p:first-child {
				font-weight: 500;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.003em;
				color: $primary-main;
				margin-bottom: 10px;
			}
			p:last-child {
				font-weight: 400;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $sub-title;
				@media screen and (max-width: 575px) {
					font-size: 14px;
				}
			}
			h2 {
				font-weight: 700;
				font-size: 32px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $color-text;
				margin-bottom: 20px;
				@media screen and (max-width: 768px) {
					font-size: 23px;
					line-height: 120%;
				}
				@media screen and (max-width: 575px) {
					font-size: 18px;
				}
			}
		}
	}

	.distribution__right {
		width: auto;
		height: auto;
		@media screen and (max-width: 1430px) {
			margin-right: -200px;
		}
		@media screen and (max-width: 992px) {
			margin-right: -295px;
		}
		@media screen and (max-width: 768px) {
			margin-right: -255px;
		}
		@media screen and (max-width: 560px) {
			margin-right: -155px;
			margin-left: 55px;
			margin-right: 0;
			height: 200px;
		}
		.ani {
			transform: translate(0, 0);
			animation: ani 0.5s linear;
			position: relative;
			width: 550px;
			height: 286px;
			background: $white;
			box-shadow: 20px 34px 59px 7px rgba(0, 25, 225, 0.05);
			border-radius: 16.9481px;
			@media screen and (max-width: 768px) {
				height: auto;
				width: 398px;
			}
			.block__one {
				padding: 17px 20px 24.5px 20px;
				display: flex;
				flex-direction: column;

				h3 {
					margin-top: 4px;
					color: #642dff;
					font-style: normal;
					font-weight: 700;
					font-size: 84.7407px;
					line-height: 120%;
					letter-spacing: 0.003em;
					text-transform: uppercase;
					background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 50.91%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
					@media screen and (max-width: 768px) {
						margin-top: 0;
						font-size: 24px;
						line-height: 120%;
					}
				}
				.block__title {
					width: 100%;
					display: flex;
					justify-content: space-between;
					p {
						@media screen and (max-width: 575px) {
							font-size: 6.85543px;
							line-height: 100%;
						}
					}
					p:first-child {
						font-weight: 400;
						font-size: 12.7111px;
						line-height: 100%;
						letter-spacing: 0.003em;
						color: $sub-title;
					}
					p:last-child {
						font-weight: 400;
						font-size: 12.7111px;
						line-height: 130%;

						letter-spacing: 0.03em;
						text-decoration-line: underline;
						color: $primary-main;
					}
				}
				.block__group {
					display: flex;
					.block__group_left {
						flex: 1;
					}
					.block__group_right {
						flex: 1;
						.block__group_title {
							font-weight: 500;
							font-size: 14.8296px;
							line-height: 130%;
							letter-spacing: 0.003em;
							color: $color-text;
							@media screen and (max-width: 575px) {
								font-size: 12px;
								line-height: 130%;
							}
						}
						.block__user {
							display: flex;
							margin-top: 10.6px;
							div {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 36px;
								height: 36px;
								border: 1.05926px solid rgba(255, 255, 255, 0.7);
								background: $stroke;
								border-radius: 50%;
								@media screen and (max-width: 575px) {
									width: 25px;
									height: 25px;
								}
								p {
									font-style: normal;
									font-weight: 500;
									font-size: 14.8296px;
									line-height: 18px;
									letter-spacing: 0.02em;
									color: $white;
								}
							}
							.block__item {
								margin-left: -10px;
								svg {
									width: 26px;
									height: 26px;
								}
							}
							.block__item_one {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 36px;
								height: 36px;
								left: 0px;
								top: 0px;
								background: $stroke;
								border: 1.05926px solid rgba(255, 255, 255, 0.7);
								border-radius: 50%;
								@media screen and (max-width: 575px) {
									padding-top: 1px;
									width: 25px;
									height: 25px;
								}
							}
							.block__item_number {
								margin-left: -12px;
								background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
								border: 2.11852px solid rgba(255, 255, 255, 0.4);
								@media screen and (max-width: 768px) {
									margin-left: -12px;
								}
								h4 {
									margin-left: -3px;
									font-weight: 400;
									font-size: 12.7111px;
									line-height: 130%;
									letter-spacing: 0.003em;
									color: $white;
									@media screen and (max-width: 768px) {
										margin-top: 0;
										font-size: 11px;
										line-height: 120%;
									}
								}
							}
						}
					}
				}
				.btn {
					margin-top: 21px;
					padding: 6.36px 0;
					width: 100%;
					display: flex;
					justify-content: center;
					background: #ffffff;
					border: 1.05926px solid $primary-main;
					border-radius: 16.95px;
					@media screen and (max-width: 575px) {
						font-size: 14px;
						line-height: 130%;
					}
					p {
						margin-left: 2.12px;
						font-weight: 400;
						font-size: 14.8296px;
						line-height: 130%;
						text-align: center;
						letter-spacing: 0.003em;
						color: $primary-main;
					}
					svg {
						width: 16.95px;
						height: 16.95px;
					}
				}
			}

			.block__two {
				animation: aniTwo 1.5s linear;
				padding: 31px;
				width: 307.99px;
				height: 143.73px;
				background: $white;
				box-shadow: 0px 21.4612px 33.7247px rgba(21, 21, 21, 0.08);
				border: 1.32px solid;
				border-radius: 15.3294px;
				border: 1px solid #4d94ff;
				width: 307.99px;
				height: 143.73px;
				position: absolute;
				top: 45px;
				left: -50px;
				@media screen and (max-width: 768px) {
					width: 100%;
					padding: 16px;
					max-width: 166px;
					height: auto;
					top: 38px;
					left: -30px;
				}
				h3 {
					font-style: normal;
					font-weight: 500;
					font-size: 18.4176px;
					line-height: 130%;
					letter-spacing: 0.003em;
					color: $color-text;
					@media screen and (max-width: 768px) {
						font-size: 9.93308px;
						line-height: 130%;
					}
				}
				.block__user {
					margin-top: 13px;
					display: flex;
					div {
						display: flex;
						justify-content: center;
						align-items: center;
						background: #e3e8ff;
						border: 1.31554px solid rgba(255, 255, 255, 0.7);
						width: 44.73px;
						height: 44.73px;
						border-radius: 50%;
					}
					.block__item_one {
						@media screen and (max-width: 768px) {
							width: 25px;
							height: 25px;
						}
						svg {
							width: 31px;
							height: 31px;
							@media screen and (max-width: 768px) {
								width: 19px;
								height: 19px;
							}
						}
					}
					.block__item {
						margin-left: -10px;
						@media screen and (max-width: 768px) {
							width: 25px;
							height: 25px;
						}
						svg {
							width: 31px;
							height: 31px;
							@media screen and (max-width: 768px) {
								width: 19px;
								height: 19px;
							}
						}
						p {
							font-style: normal;
							font-weight: 500;
							font-size: 18.4176px;
							line-height: 22px;
							letter-spacing: 0.02em;
							color: $white;
						}
					}
					.block__item_number {
						margin-left: -12px;
						width: 44.73px;
						height: 44.73px;
						background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
						border: 2.63108px solid rgba(255, 255, 255, 0.4);
						@media screen and (max-width: 768px) {
							margin-left: -10px;
							width: 25px;
							height: 25px;
						}

						h4 {
							margin-left: -2px;
							font-weight: 400;
							font-size: 15.7865px;
							line-height: 130%;
							letter-spacing: 0.003em;
							color: $white;
							@media screen and (max-width: 768px) {
								margin-top: 0;
								font-size: 11px;
								line-height: 120%;
							}
						}
					}
				}
			}
		}
	}

	@keyframes aniTwo {
		0% {
			opacity: 0;
		}
		20% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes ani {
		from {
			transform: translate(+100%, 0);
		}
		to {
			transform: translate(0, 0);
		}
	}
}
