@import "../variables.scss";
.container {
	position: relative;
	max-width: 1440px;
	width: calc(100% - 200px);
	margin: 0 auto;
	height: 296px;
	overflow: hidden;
	background: #ffffff;
	box-shadow: -10px 44px 89px 8px rgba(0, 25, 225, 0.05);
	border-radius: 20px;
	padding-left: 60px;

	@media screen and (max-width: 1285px) {
		width: calc(100% - 100px);
	}
	@media screen and (max-width: 1050px) {
		width: calc(100% - 60px);
		padding: 20px;
	}
	@media screen and (max-width: 992px) {
		padding: 40px;
	}
	@media screen and (max-width: 575px) {
		padding: 20px;
		height: 284px;
	}
	@media screen and (max-width: 560px) {
		box-shadow: none;
		overflow: visible;
		padding: 0;
		height: 338px;
	}
	@media screen and (max-width: 450px) {
		height: 330px;
		width: calc(100% - 36px);
	}
	.partner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		@media screen and (max-width: 560px) {
			flex-direction: column;
		}
		.partner__left {
			width: 550px;
			height: 216px;
			@media screen and (max-width: 992px) {
				width: 378px;
			}
			@media screen and (max-width: 575px) {
				width: 305px;
				height: auto;
			}
			@media screen and (max-width: 560px) {
				width: 100%;
			}
			.info {
				p:first-child {
					font-weight: 500;
					font-size: 14px;
					line-height: 130%;
					letter-spacing: 0.003em;
					color: $primary-main;
					margin-bottom: 10px;
				}
				p:last-child {
					font-weight: 400;
					font-size: 14px;
					line-height: 120%;
					letter-spacing: 0.003em;
					color: $sub-title;
					margin-bottom: 30px;
					@media screen and (max-width: 575px) {
						margin: 0;
					}
				}
				h2 {
					width: 100%;
					font-weight: 700;
					font-size: 42px;
					line-height: 120%;
					letter-spacing: 0.003em;
					color: $color-text;
					margin-bottom: 20px;
					@media screen and (max-width: 992px) {
						font-size: 32px;
						line-height: 120%;
					}
					@media screen and (max-width: 768px) {
						font-size: 23px;
						line-height: 120%;
					}
					@media screen and (max-width: 560px) {
						font-size: 18px;
						line-height: 120%;
					}
				}
			}
			a {
				@media screen and (max-width: 560px) {
					display: none;
				}
			}
			.btn {
				display: flex;
				.partner__btnInvite {
					max-width: 255px;
					padding: 10px 20px;
					height: 40px;
					background: $white;
					border: 1px solid $primary-main;
					color: $primary-main;
					border-radius: 22px;
					transition: all 0.5s ease-in-out;

					display: flex;
					align-items: center;
					justify-content: center;

					gap: 4px;
					@media screen and (max-width: 575px) {
						margin-top: 74px;
					}

					p {
						font-weight: 500;
						font-size: 16px;
						line-height: 120%;
					}
					&:hover {
						color: #5d43ff;
						border: 1px solid #5d43ff;
					}
				}
			}
		}
		.partner__right {
			position: relative;
			display: flex;
			width: 550px;
			height: 100%;
			@media screen and (max-width: 992px) {
				width: 400px;
			}
			@media screen and (max-width: 768px) {
				width: 278px;
			}
			@media screen and (max-width: 660px) {
				width: 100%;
			}

			.block {
				width: 100%;
				@media screen and (max-width: 768px) {
					position: absolute;
					right: 45px;
					width: 100%;
					height: 100%;
				}
				@media screen and (max-width: 560px) {
					right: 0;
				}
				div {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					width: 280px;
					height: 280px;
					@media screen and (max-width: 992px) {
						width: 190px;
						height: 190px;
					}
					@media screen and (max-width: 768px) {
						width: 120px;
						height: 120px;
					}
					img {
						@media screen and (max-width: 992px) {
							width: 90px;
						}
						@media screen and (max-width: 768px) {
							width: 50px;
						}
						@media screen and (max-width: 560px) {
							width: 80px;
						}
					}
				}
				.right__image_one {
					position: absolute;
					z-index: 7;
					left: 0px;
					top: 97px;
					transform: translateY(150px);
					background: $color-text;
					-webkit-box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
					box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
					border-radius: 150px;
					-webkit-animation: aniOne 9s infinite;
					animation: aniOne 9s infinite;
					@media screen and (max-width: 768px) {
						animation-name: aniOne768;
						top: 172px;
					}
					@media screen and (max-width: 575px) {
						left: 30px;
						top: -27px;
						animation-name: none;
					}
					@media screen and (max-width: 560px) {
						animation-name: none;
						width: 170px;
						height: 170px;
						left: auto;
						right: 0;
						top: -145px;
					}
					@media screen and (max-width: 420px) {
						right: -40px;
					}
				}
				.right__image_two {
					position: absolute;
					z-index: 7;
					left: 270px;
					background: #a8dadc;
					-webkit-box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
					box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
					border-radius: 150px;

					-webkit-animation: aniTwo 9s infinite;
					animation: aniTwo 9s infinite;
					@media screen and (max-width: 1280px) {
						left: 250px;
					}
					@media screen and (max-width: 768px) {
						animation-name: aniTwo768;
						left: 120px;
					}
					@media screen and (max-width: 575px) {
						top: 0;
						left: 100px;
						animation-name: none;
					}

					@media screen and (max-width: 560px) {
						animation-name: none;
						width: 170px;
						height: 170px;
						left: -20px;
						top: 60px;
					}
					@media screen and (max-width: 400px) {
						left: -30px;
					}
				}
				.blockBlur {
					position: absolute;
					z-index: 5;
					width: 520px !important;
					height: 520px !important;
					left: 281px;
					top: 115px;
					background: linear-gradient(113.48deg, #ff8de6 7.05%, #4c3fe7 105.43%);
					opacity: 0.3;
					filter: blur(112px);
					@media screen and (max-width: 992px) {
						left: 175px;
						top: 35px;
					}
					@media screen and (max-width: 768px) {
						left: -130px;
						top: 20px;
						opacity: 0.25;
					}
					@media screen and (max-width: 560px) {
						display: none;
					}
				}
			}

			@keyframes aniOne {
				0% {
					transform: translateY(0px);
				}
				50% {
					transform: translateY(-160px);
				}
				100% {
					transform: translateY(0px);
				}
			}
			@keyframes aniOne768 {
				0% {
					top: 172px;
				}
				50% {
					top: 0;
				}
				100% {
					top: 172px;
				}
			}
			@keyframes aniTwo {
				0% {
					transform: translateY(-60px);
				}
				50% {
					transform: translateY(95px);
				}
				100% {
					transform: translateY(-60px);
				}
			}
			@keyframes aniTwo768 {
				0% {
					top: 0;
				}
				50% {
					top: 172px;
				}
				100% {
					top: 0;
				}
			}
		}
	}
}

.link {
	position: absolute;
	top: -150px;
}
