$primary-main: #748bff;
$primary-light: #acb9ff;
$primary-lighter: #d5dcff;
$stroke: #e3e8ff;
$hover: #f1f3ff;
$secondary: #642dff;
$color-text: #1e1e1e;
$sub-title: #636363;
$text-non-active: #afafaf;
$text-small: #f3f3f3;
$white: #ffffff;
$background-white: #fafaff;
$disabled-background: #cacaca;
$error: #ff5f71;
$disabled-background: #cacaca;
