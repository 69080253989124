@import "../variables.scss";

.container {
	position: relative;
	max-width: 1440px;
	width: calc(100% - 200px);
	margin: 0 auto;
	height: 500px;
	display: flex;
	justify-content: space-between;
	gap: 40px;
	background-color: $white;
	@media screen and (max-width: 1285px) {
		width: calc(100% - 100px);
		height: auto;
	}
	@media screen and (max-width: 992px) {
		height: 450px;
	}
	@media screen and (max-width: 768px) {
		height: 400px;
	}
	@media screen and (max-width: 575px) {
		width: calc(100% - 60px);
		height: 555px;
	}
	@media screen and (max-width: 560px) {
		height: auto;
	}
	@media screen and (max-width: 450px) {
		width: calc(100% - 36px);
	}
	&__info {
		position: relative;
		z-index: 6;
		max-width: 685px;

		@media screen and (max-width: 1280px) {
			max-width: 685px;
			width: 100%;
		}
		@media screen and (max-width: 992px) {
			max-width: 530px;
		}

		@media screen and (max-width: 768px) {
			width: 100%;
		}

		.container__title {
			width: 100%;
			color: $color-text;
			font-weight: bold;
			font-size: 52px;
			line-height: 120%;
			letter-spacing: 0.003em;
			background: transparent;
			@media screen and (max-width: 1439px) {
				font-size: 48px;
			}
			@media screen and (max-width: 1120px) {
				font-size: 42px;
				line-height: 120%;
			}

			@media screen and (max-width: 680px) {
				font-size: 42px;
				line-height: 120%;
			}
			@media screen and (max-width: 560px) {
				font-size: 24px;
			}
		}

		.container__subTitle {
			max-width: 600px;
			width: 100%;
			margin-top: 20px;
			font-weight: 400;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $sub-title;
			background: transparent;
			@media screen and (max-width: 1120px) {
				width: 100%;
			}
			@media screen and (max-width: 680px) {
				font-size: 16px;
				line-height: 120%;
			}
		}

		.container__btn {
			width: 545px;
			margin-top: 50px;
			display: flex;
			align-items: center;
			gap: 16px;
			@media screen and (max-width: 560px) {
				flex-direction: column;
				align-items: start;
				margin-top: 40px;
			}
			.btn__invite {
				width: auto;
				cursor: pointer;
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				gap: 4px;
				background: -o-linear-gradient(44.88deg, #7aafff 7.03%, #642dff 98.91%);
				background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
				border-radius: 22px;
				padding: 10px 20px;
				z-index: 49;
				p {
					color: $white;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 120%;
					letter-spacing: 0.003em;
				}
				&::before {
					content: "";
					display: block;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: -o-linear-gradient(40.81deg, #642dff 1.96%, #7aafff 86.77%);
					background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
					-webkit-transition: opacity 0.2s ease-in-out;
					-o-transition: opacity 0.2s ease-in-out;
					transition: opacity 0.2s ease-in-out;
					border-radius: 22px;
					z-index: -49;
				}

				&:hover::before {
					background: -o-linear-gradient(40.81deg, #642dff 1.96%, #7aafff 86.77%);
					background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
					opacity: 1;
				}
			}
			.btn__conditions {
				cursor: pointer;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				gap: 10.67px;
				padding: 10px 20px;
				background: rgba(255, 255, 255, 0.5);
				border: 1px solid $primary-main;
				-webkit-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				-webkit-backdrop-filter: blur(18px);
				backdrop-filter: blur(18px);
				border-radius: 22px;
				color: $primary-main;

				@media screen and (max-width: 560px) {
					background: transparent;
					border: none;
					padding: 0;
				}
				P {
					font-weight: 500;
					font-size: 16px;
					line-height: 120%;

					letter-spacing: 0.003em;
				}
				&:hover {
					border: 1px solid #5d43ff;
					color: #642dff;
					@media screen and (max-width: 560px) {
						border: none;
						color: #642dff;
					}
				}
			}
		}
		.block {
			max-width: 580px;
			display: flex;
			flex-direction: column;
			gap: 30px;
			margin-top: 103px;

			@media screen and (max-width: 575px) {
				margin-top: 50px;
			}
			@media screen and (max-width: 560px) {
				margin-top: 60px;
			}
			.info {
				display: flex;
				align-items: center;
				gap: 20px;
				width: 364px;
				@media screen and (max-width: 992px) {
					align-items: center;
					flex-direction: row;
				}
				@media screen and (max-width: 575px) {
					align-items: center;
				}
				@media screen and (max-width: 560px) {
					flex-direction: column;
					align-items: flex-start;
					width: auto;
				}
				.info__icon {
					display: flex;
					padding: 7px;
					width: 36px;
					height: 36px;
					background: $primary-main;
					box-shadow: 0px 2px 16px rgba(116, 139, 255, 0.51), inset 2px 2.66667px 4.66667px rgba(255, 255, 255, 0.31);
					border-radius: 26.6667px;
					img {
						padding: 0;
					}
				}
				div:last-child {
					display: flex;
					flex-direction: column;
					gap: 10px;
					h4 {
						width: 100%;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 120%;
						letter-spacing: 0.003em;
						color: $color-text;
					}
					p {
						width: 100%;
						font-family: "Gotham Pro";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 130%;
						letter-spacing: 0.003em;
						color: $sub-title;
					}
				}
			}
			.blockBlur {
				position: absolute;
				z-index: 1;
				top: 200px;
				left: -160px;
				width: 350px;
				height: 350px;
				opacity: 0.1;
				-webkit-filter: blur(50px);
				filter: blur(50px);
				display: none;
				@media screen and (max-width: 560px) {
					width: 250px;
					height: 250px;
					display: block;
					top: 90px;
					left: 185px;
				}
				img {
					@media screen and (max-width: 560px) {
						width: 250px;
						height: 250px;
					}
				}
			}
			.blockBlur__two {
				position: absolute;
				width: 480px;
				height: 480px;
				opacity: 0.1;
				-webkit-filter: blur(50px);
				filter: blur(50px);
				display: none;
				@media screen and (max-width: 560px) {
					width: 250px;
					height: 250px;
					display: block;
					top: -35px;
					left: -80px;
				}
				img {
					@media screen and (max-width: 560px) {
						width: 250px;
						height: 250px;
					}
				}
			}
		}
	}
	&__bg {
		position: relative;

		z-index: 1;
		width: 610px;
		height: auto;
		@media screen and (max-width: 992px) {
			left: -80px;
		}
		@media screen and (max-width: 560px) {
			display: none;
		}
		.bg {
			position: relative;
			z-index: 2;
			width: 750px;
			height: auto;
			margin-top: -16px;
			@media screen and (max-width: 992px) {
				width: 650px;
				margin-top: 10px;
			}
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		@media screen and (max-width: 992px) {
			width: 320px;
			left: 10px;
		}
		.blockBlur {
			position: absolute;
			z-index: 1;
			top: 200px;
			left: -160px;
			width: 300px;
			height: 300px;
			opacity: 0.2;
			img {
				width: 100%;
				height: 100%;
			}
			-webkit-filter: blur(50px);
			filter: blur(50px);
			@media screen and (max-width: 1280px) {
				left: -80px;
			}
			@media screen and (max-width: 992px) {
				top: 40px;
			}
			@media screen and (max-width: 768px) {
				top: 250px;
				left: -140px;
			}
			@media screen and (max-width: 575px) {
				opacity: 0.15;
				top: 160px;
				left: -290px;
			}
			@media screen and (max-width: 560px) {
				opacity: 0.2;
				top: 65px;
				width: 250px;
				height: 250px;
				display: none;
			}
			img {
				@media screen and (max-width: 560px) {
					width: 250px;
					height: 250px;
				}
			}
		}

		.blockBlur__two {
			position: absolute;
			width: 480px;
			height: 480px;
			top: -60px;
			right: -350px;
			opacity: 0.2;
			-webkit-filter: blur(50px);
			filter: blur(50px);
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
	}
}
