@import "../variables.scss";

.container {
	position: relative;
	max-width: 1440px;
	width: calc(100% - 200px);
	margin: 120px auto;
	@media screen and (max-width: 1285px) {
		width: calc(100% - 100px);
		margin: 0 auto;
	}
	@media screen and (max-width: 1050px) {
		width: calc(100% - 60px);
	}
	@media screen and (max-width: 768px) {
		margin: 0 auto;
	}
	@media screen and (max-width: 450px) {
		width: calc(100% - 36px);
	}
	.conditions__top {
		.top__subTitle {
			font-weight: 500;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.003em;
			color: $primary-main;
			margin-bottom: 20px;
			@media screen and (max-width: 992px) {
			}
		}
		.top__title {
			max-width: 1000px;
			width: 100%;
			font-weight: 700;
			font-size: 42px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $color-text;
			@media screen and (max-width: 1050px) {
				font-size: 32px;
				line-height: 120%;
				max-width: 762px;
			}
			@media screen and (max-width: 575px) {
				font-size: 23px;
				line-height: 120%;
			}
			br {
				display: none;
				@media screen and (max-width: 580px) {
					display: block;
				}
			}
		}
	}
	.conditions__middle {
		background: $white;
		box-shadow: 20px 34px 59px 7px rgba(0, 25, 225, 0.05);
		border-radius: 20px;
		margin: 40px 0;
		@media screen and (max-width: 560px) {
			background: #ffffff;
			border: 1px solid #ffffff;
			box-shadow: 20px 34px 59px 7px rgba(0, 25, 225, 0.05);
			border-radius: 10px;
			margin: 20px 0;
		}
		.middle {
			display: flex;
			flex-direction: column;
			padding: 40px 60px;
			@media screen and (max-width: 1050px) {
				padding: 40px;
			}
			@media screen and (max-width: 768px) {
				padding: 20px;
			}
			div {
				display: grid;
				grid-template-columns: 1fr 1fr;
				align-items: center;
				@media screen and (max-width: 682px) {
					grid-template-columns: 2fr 1fr;
				}
				@media screen and (max-width: 536px) {
					display: flex;
					justify-content: space-between;
					gap: 4px;
				}
				p {
					padding: 12px 0;
				}
			}
			.text {
				@media screen and (max-width: 536px) {
					border-bottom: 1px solid #e3e8ff;
				}
				p {
					border-bottom: 1px solid #e3e8ff;
					font-size: 16px;
					line-height: 120%;
					letter-spacing: 0.003em;
					color: $color-text;
					@media screen and (max-width: 536px) {
						font-size: 14px;
						line-height: 130%;
						border: none;
					}
				}
				.left__text {
					font-weight: 400;
					border-right: 1px solid #e3e8ff;
					@media screen and (max-width: 536px) {
						border: none;
					}
				}
				.right__text {
					font-weight: 500;
					padding-left: 60px;
					@media screen and (max-width: 1050px) {
						padding-left: 40px;
					}
					@media screen and (max-width: 768px) {
						padding-left: 20px;
					}
					@media screen and (max-width: 536px) {
						text-align: end;
						padding: 0;
						width: 80px;
					}
				}
			}
			.text_bottom {
				.bottom__text_left {
					border-right: 1px solid #e3e8ff;
					@media screen and (max-width: 536px) {
						border: none;
						font-size: 14px;
					}
					br {
						display: none;
						@media screen and (max-width: 575px) {
							display: block;
						}
					}
				}
				.bottom__text_right {
					font-size: 16px;
					line-height: 130%;
					font-weight: 500;
					padding-left: 60px;
					@media screen and (max-width: 1050px) {
						padding-left: 40px;
					}
					@media screen and (max-width: 768px) {
						padding-left: 20px;
					}
					@media screen and (max-width: 575px) {
						padding: 0;
						padding-left: 20px;
						font-size: 16px;
						line-height: 130%;
					}
					@media screen and (max-width: 536px) {
						text-align: end;
						font-size: 14px;
					}
				}
			}
		}
	}
	.conditions__bottom {
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 992px) {
			gap: 20px;
		}
		@media screen and (max-width: 768px) {
			flex-direction: column;
		}
		span {
			max-width: 780px;
			width: 100%;
			font-weight: 500;
			font-size: 18px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $color-text;
			@media screen and (max-width: 992px) {
				max-width: 606px;
			}
			br {
				display: none;
				@media screen and (max-width: 368px) {
					display: block;
				}
			}
		}

		.btn__invite {
			a {
				max-width: 258px;
				cursor: pointer;
				position: relative;
				display: flex;
				background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
				border-radius: 22px;
				padding: 10.5px 20px;
				z-index: 49;
				@media screen and (max-width: 420px) {
					width: 100%;
					padding: 10px 0;
					justify-content: center;
				}
				p {
					color: $white;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 120%;
					letter-spacing: 0.003em;
				}
				&::before {
					content: "";
					display: block;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
					transition: opacity 0.2s ease-in-out;
					border-radius: 22px;
					z-index: -49;
				}

				&:hover::before {
					background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
					opacity: 1;
				}
			}
		}
		.btn__inviteEN {
			a {
				max-width: 167px;
				cursor: pointer;
				position: relative;
				display: flex;
				background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
				border-radius: 22px;
				padding: 10.5px 20px;
				z-index: 49;
				@media screen and (max-width: 420px) {
					width: 100%;
					padding: 10px 0;
					justify-content: center;
				}
				p {
					color: $white;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 120%;
					letter-spacing: 0.003em;
				}
				&::before {
					content: "";
					display: block;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
					transition: opacity 0.2s ease-in-out;
					border-radius: 22px;
					z-index: -49;
				}

				&:hover::before {
					background: linear-gradient(49.19deg, #642dff 1.96%, #7aafff 86.77%);
					opacity: 1;
				}
			}
		}
	}
}
.link {
	position: absolute;
	top: -150px;
}
