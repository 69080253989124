@import "../../components/variables.scss";

.dropdown {
	width: 100%;
	height: 50px;

	@media screen and (max-width: 450px) {
		width: 100%;
	}
	&:hover .dropdown__select {
		background: #ffffff;
	}

	.dropdown__select {
		position: relative;
		transition: 0.4s;
		cursor: pointer;
		border: 1px solid #d5dcff;
		border-bottom: none;
		transition: all 0.2s ease-in-out;
		width: 100%;
		height: 50px;
		padding: 16px;
		gap: 4px;
		background: $white;
		border-top-left-radius: 22px;
		border-top-right-radius: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			position: absolute;
			top: -12px;
			transition: 0.4s;
			background: $white;
			font-weight: 400;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.003em;
			color: $text-non-active;
		}

		p {
			font-size: 14px;
		}
		img {
		}
		.active {
			transition: all 0.3s;
			transform: rotate(180deg);
		}
	}

	.dropdown__list {
		position: relative;
		z-index: 7;
		width: 100%;
		border: 1px solid #e3e8ff;
		border-top: none;
		box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
		background: $white;
		flex-direction: column;
		align-items: center;
		display: flex;
		transition: opacity 0.25s linear, visibility 0.25s linear;
		border-bottom-left-radius: 22px;
		border-bottom-right-radius: 22px;
		.dropdown__list__item {
			cursor: pointer;
			width: 100%;
			padding: 15px 16px;
			transition: 0.3s;
			&:hover {
				background: #f1f3ff;

				border-radius: 6px;
			}

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 120%;
			}
		}
		.dropdown__list__itemActive {
			width: 100%;
			padding: 8px 0 6px 8px;
			color: $primary-main;
			background: $hover;
			border-radius: 6px;

			p {
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 120%;
			}
		}
	}
}

.dropdown__none {
	width: 100%;
	height: 50px;
	@media screen and (max-width: 450px) {
		width: 100%;
	}
	&:hover .dropdown__select {
		background: none;
		border: 1px solid $primary-main;

		border-radius: 22px;
	}
	.dropdown__select {
		position: relative;
		transition: all 0.2s ease-in-out;
		border: 1px solid #d5dcff;
		background: none;
		cursor: pointer;
		width: 100%;
		height: 50px;
		padding: 16px;
		gap: 4px;
		border-radius: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.activeNone {
			transition: all 0.2s linear;
		}

		span {
			position: absolute;
			top: -12px;
			background: $white;
			font-weight: 400;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.003em;
			color: $text-non-active;
		}
		.select__none {
			transition: all 0.2s linear;
			top: 14px;
		}
		.select__undefined {
			top: 14px;
			transition: all 0.2s linear;
		}
		.selectUndefined {
			transition: all 0.2s linear;
		}
		.select {
			top: 0;
		}

		p {
			font-size: 14px;
		}
		img {
			transform: rotate(0deg);
		}
	}

	.dropdown__list {
		opacity: 0;
		visibility: hidden;
		border-bottom-left-radius: 22px;
		border-bottom-right-radius: 22px;
	}
}
