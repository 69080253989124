@import "../variables.scss";

.contact {
	position: relative;
	max-width: 1440px;
	width: calc(100% - 200px);
	margin: 120px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 40px;

	@media screen and (max-width: 1285px) {
		width: calc(100% - 100px);
		margin: 0 auto;
	}
	@media screen and (max-width: 1050px) {
		width: calc(100% - 60px);
		padding: 0;
	}
	@media screen and (max-width: 992px) {
		gap: 100px;
		height: 270px;
	}
	@media screen and (max-width: 768px) {
		margin: 0 auto;
	}
	@media screen and (max-width: 575px) {
		height: 220px;
		gap: 80px;
	}
	@media screen and (max-width: 450px) {
		width: calc(100% - 36px);
	}
	.contact__left {
		width: 666px;
		height: 247px;
		@media screen and (max-width: 992px) {
			width: 420px;
			height: auto;
		}
		.contact__subTitle {
			font-weight: 500;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.003em;
			color: $primary-main;
			margin-bottom: 10px;
			@media screen and (max-width: 575px) {
				margin-bottom: 4px;
			}
		}
		.contact__left_title {
			max-width: 640px;
			font-weight: 700;
			font-size: 42px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $color-text;
			margin-bottom: 20px;
			@media screen and (max-width: 992px) {
				font-size: 32px;
				line-height: 120%;
			}
			@media screen and (max-width: 768px) {
				font-size: 23px;
				line-height: 120%;
			}
			@media screen and (max-width: 575px) {
				max-width: 369px;
			}
		}
		.contact__left_titleRu {
			font-weight: 700;
			font-size: 42px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $color-text;
			margin-bottom: 20px;
			@media screen and (max-width: 992px) {
				font-size: 32px;
				line-height: 120%;
			}
			@media screen and (max-width: 768px) {
				font-size: 23px;
				line-height: 120%;
			}
			@media screen and (max-width: 575px) {
				max-width: 369px;
			}
		}
		.contact__left_time {
			font-size: 16px;
			line-height: 120%;
			letter-spacing: -0.007em;
			color: $sub-title;
			@media screen and (max-width: 992px) {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
			span {
				margin-left: 8px;
				font-weight: 500;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $color-text;
				@media screen and (max-width: 575px) {
					margin: 0;
				}
			}
		}
		.support {
			margin-top: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 6px;
			width: 210px;
			height: 40px;
			background: $primary-main;
			backdrop-filter: blur(18px);
			border-radius: 22px;
			transition: all 0.2s linear;
			&:hover {
				background: #5d43ff;
			}
			p {
				font-weight: 500;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $white;
			}
		}
	}
	.contact__right {
		flex: 1;
		position: relative;
		height: 350px;
		display: flex;
		@media screen and (max-width: 992px) {
			height: 100%;
		}
		@media screen and (max-width: 768px) {
			display: none;
		}
		.iconOne {
			background: $white;
			position: absolute;
			z-index: 4;
			left: 167px;
			top: 0px;
			animation: aniOne 1.3s infinite;

			@media screen and (max-width: 1280px) {
				left: 120px;
			}
			@media screen and (max-width: 992px) {
				left: 110px;
			}
			img {
				@media screen and (max-width: 992px) {
					width: 112px;
				}
			}
		}
		.iconTwo {
			background: $white;
			position: absolute;
			z-index: 3;
			left: 0px;
			top: 128px;
			animation: aniTwo 1.6s infinite;
			img {
				@media screen and (max-width: 992px) {
					width: 160px;
				}
			}
		}
		.iconThree {
			background: $white;
			position: absolute;
			z-index: 2;
			left: 232px;
			top: 34px;
			animation: aniThree 1.9s infinite;
			@media screen and (max-width: 1280px) {
				left: 175px;
			}
			@media screen and (max-width: 992px) {
				left: 150px;
			}
			img {
				@media screen and (max-width: 992px) {
					width: 244.67px;
				}
			}
		}
	}
	@keyframes aniOne {
		0% {
			transform: translateY(4px);
		}
		50% {
			transform: translateY(0px);
		}
		100% {
			transform: translateY(4px);
		}
	}
	@keyframes aniTwo {
		0% {
			transform: translateY(4px);
		}
		50% {
			transform: translateY(0px);
		}
		100% {
			transform: translateY(4px);
		}
	}
	@keyframes aniThree {
		0% {
			transform: translateY(4px);
		}
		50% {
			transform: translateY(0px);
		}
		100% {
			transform: translateY(4px);
		}
	}
}

.link {
	@media screen and (max-width: 560px) {
		position: absolute;
		top: -150px;
	}
}
