.home {
	margin-top: 140px;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 1280px) {
		gap: 100px;
	}
	@media screen and (max-width: 992px) {
		margin-top: 120px;
	}
	@media screen and (max-width: 575px) {
		gap: 80px;
	}
	@media screen and (max-width: 560px) {
		gap: 60px;
	}
}
