@import "../variables.scss";

.container {
	max-width: 1440px;
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-top: 140px;
	@media screen and (max-width: 1285px) {
		width: calc(100% - 100px);
	}
	@media screen and (max-width: 1050px) {
		max-width: 932px;
		width: calc(100% - 60px);
	}
	@media screen and (max-width: 450px) {
		width: calc(100% - 36px);
	}
	&__title {
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.003em;
			color: $primary-main;
			margin-bottom: 20px;
		}
		h2 {
			font-weight: 700;
			font-size: 42px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $color-text;
			@media screen and (max-width: 992px) {
				font-size: 32px;
			}
			@media screen and (max-width: 430px) {
				font-size: 24px;
			}
		}
	}

	&__info {
		margin-top: 40px;
		display: flex;
		gap: 40px;
		@media screen and (max-width: 920px) {
			overflow: auto;
			padding-bottom: 30px;
			&::-webkit-scrollbar {
				display: none;
			}
		}

		.info__item {
			flex: 1;
			padding: 20px;
			min-height: 154px;
			border: 1px solid #ffffff;
			background: #ffffff;
			box-shadow: 0px 14px 22px rgba(21, 21, 21, 0.08);
			border-radius: 20px;
			&:hover {
				border: 1px solid #642dff;
			}
			&:focus {
				border: 1px solid #642dff;
			}
			@media screen and (max-width: 920px) {
				flex: none;
				width: 176px;
			}
			@media screen and (max-width: 560px) {
				width: 250px;
			}
			.info__icon {
				padding: 4px;
				width: 32px;
				height: 32px;
				background: #e3e8ff;
				border-radius: 8px;
			}
			.info__text {
				margin-top: 10px;
				font-weight: 400;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.003em;
				color: $sub-title;
			}
		}
	}
}
