@import "../variables.scss";

.container {
	max-width: 1440px;
	width: calc(100% - 200px);
	margin: 120px auto 60px auto;
	@media screen and (max-width: 1285px) {
		margin-top: 104px;
		width: calc(100% - 100px);
	}
	@media screen and (max-width: 1270px) {
		margin-top: 60px;
		max-width: 932px;
		width: calc(100% - 60px);
	}
	@media screen and (max-width: 920px) {
		margin-top: 90px;
	}
	@media screen and (max-width: 768px) {
		margin-top: 80px;
	}
	@media screen and (max-width: 450px) {
		margin-top: 10px;
		width: calc(100% - 36px);
	}
	&__title {
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 130%;
			letter-spacing: 0.003em;
			color: $primary-main;
			margin-bottom: 20px;
		}
		h2 {
			font-weight: 700;
			font-size: 32px;
			line-height: 120%;
			letter-spacing: 0.003em;
			color: $color-text;

			@media screen and (max-width: 560px) {
				font-size: 18px;
			}
		}
	}
	.invite {
		height: 690px;
		overflow: hidden;
		margin-top: 40px;
		padding: 60px;
		display: flex;
		justify-content: space-between;
		background: $white;
		border: 1px solid #e3e8ff;
		box-shadow: 20px 34px 59px 7px rgba(0, 25, 225, 0.05);
		border-radius: 20px;
		@media screen and (max-width: 1270px) {
			padding: 40px 0 60px 40px;
			gap: 34px;
		}
		@media screen and (max-width: 992px) {
			height: 690px;
		}
		@media screen and (max-width: 768px) {
			padding: 60px;
			justify-content: center;
		}
		@media screen and (max-width: 575px) {
			height: 650px;
		}
		@media screen and (max-width: 560px) {
			height: auto;
			overflow: visible;
		}
		@media screen and (max-width: 450px) {
			padding: 0;
			margin-top: 20px;
			box-shadow: none;
			border: none;
		}
		.card__img {
			filter: drop-shadow(0px 30.8803px 30.8803px rgba(0, 0, 0, 0.1));
			position: relative;
			width: 675px;
			@media screen and (max-width: 1380px) {
				margin-right: -100px;
			}
			@media screen and (max-width: 1270px) {
				margin: 0;
				width: 100%;
			}
			@media screen and (max-width: 990px) {
				margin-left: -50px;
			}
			@media screen and (max-width: 768px) {
				display: none;
			}
			img {
				width: 530px;
				@media screen and (max-width: 1270px) {
					width: 350px;
				}
			}
			.card__imgOne {
				filter: drop-shadow(0px 44.7478px 44.7478px rgba(0, 0, 0, 0.1));
				position: absolute;
				z-index: 5;
				left: -19px;
				top: 40px;

				@media screen and (max-width: 1270px) {
					left: 65px;
					top: 125px;
				}
			}
			.card__imgTwo {
				filter: drop-shadow(0px 44.5588px 44.5588px rgba(0, 0, 0, 0.1));
				position: absolute;
				top: -32px;
				left: 315px;
				@media screen and (max-width: 1270px) {
					top: 78px;
					left: 285px;
				}
			}
			.card__imgThree {
				filter: drop-shadow(0px 44.7477px 44.7477px rgba(0, 0, 0, 0.1));
				position: absolute;
				z-index: 4;
				left: 80px;
				bottom: -70px;

				@media screen and (max-width: 1270px) {
					left: 130px;
					bottom: 36px;
				}
				@media screen and (max-width: 992px) {
					bottom: 12px;
				}
			}
			.blockBlur {
				position: absolute;
				width: 520px;
				height: 520px;
				left: 50px;
				top: 122px;
				background: linear-gradient(45.12deg, #7aafff 7.03%, #642dff 98.91%);
				opacity: 0.2;
				filter: blur(112px);
			}
		}
		.form {
			display: flex;
			flex-direction: column;
			gap: 24px;
			@media screen and (max-width: 450px) {
				width: 100%;
				align-items: center;
				padding: 5px 0;
			}
			input {
				font-size: 14px;
			}
			.labs {
				position: relative;
				width: 100%;
				span {
					position: absolute;
					z-index: 2;
					top: 17px;
					left: 14px;
					font-size: 15px;
					@media screen and (max-width: 768px) {
						top: 18px;
					}
				}
				input {
					padding-left: 32px;
				}
			}
			.lab {
				position: relative;
				width: 100%;
				span {
					position: absolute;
					z-index: 2;
					top: 18px;
					left: 14px;
					font-size: 14px;
					@media screen and (max-width: 768px) {
						top: 19px;
					}
				}
				input {
					padding-left: 26px;
				}
			}

			.btn__noActive {
				display: flex;
				justify-content: center;
				padding: 6px 12px;
				gap: 2px;
				max-width: 358px;
				width: 100%;
				height: 50px;
				background: #d5dcff;
				border-radius: 22px;
				align-items: center;
				@media screen and (max-width: 450px) {
					max-width: 100%;
				}
				P {
					padding-bottom: 2px;
					color: $white;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 130%;
				}
			}

			.btn__active {
				transition: 0.2s;
				padding: 6px 12px;
				gap: 2px;
				max-width: 358px;
				width: 100%;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #748bff;
				border-radius: 22px;
				@media screen and (max-width: 450px) {
					max-width: 100%;
				}
				p {
					padding-bottom: 2px;
					color: $white;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 130%;
				}
				&:hover {
					background: #5d43ff;
				}
			}
		}
		.check {
			width: 318px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			gap: 20px;
			.check__icon {
				width: 100px;
				height: 100px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #b3eddd;
				border-radius: 50%;
			}
			.check__text {
				p:first-child {
					font-weight: 500;
					font-size: 18px;
					line-height: 120%;
					letter-spacing: 0.003em;
					color: $color-text;
				}
				p:last-child {
					width: 200px;
					margin: 10px auto 0 auto;
					font-weight: 400;
					font-size: 14px;
					line-height: 130%;
					text-align: center;
					letter-spacing: 0.003em;
					color: $sub-title;
				}
			}
			.check__btn {
				transition: 0.2s;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				justify-content: center;
				align-items: flex-end;
				padding: 12px 20px 10px 15px;
				width: 275px;
				height: 40px;
				background: $primary-main;
				border-radius: 22px;
				font-weight: 500;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $white;
				&:hover {
					background: #5d43ff;
				}
				img {
					transform: rotate(180deg) translateY(0.5px);
				}
			}
			.check__btnEN {
				transition: 0.2s;
				margin: 0 auto;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				padding: 12px 20px 10px 15px;
				width: 275px;
				height: 40px;
				background: $primary-main;
				border-radius: 22px;
				font-weight: 500;
				font-size: 16px;
				line-height: 120%;
				letter-spacing: 0.003em;
				color: $white;
				&:hover {
					background: #5d43ff;
				}
				img {
					transform: rotate(180deg) translateY(0.5px);
				}
			}
		}
	}
}
